import React from "react";
import serviceimg from "./assets/service.jpeg";
const Service = () => {
  return (
    <>
      <div className="container-fluid bg-hero" id="Service">
        <div className="container">
          <img src={serviceimg} alt="" className="img-fluid w-100" />
        </div>
      </div>
    </>
  );
};

export default Service;
