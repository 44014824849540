import React from "react";
import "./Footer.css";
import openseaImg from "./assets/opensea.svg";

const Footer = () => {
	return (
		<>
			<div className="container-fluid py-5">
				<div className="container">
					<hr />
					<div className="row align-items-center pt-5">
						<div className="col-md-6">
							<h1
								style={{
									fontFamily: "Luckiest Guy, cursive",
									cursor: "pointer",
								}}
							>
								FERTILE GUPPIES
							</h1>
						</div>
						<div className="col-md-6 d-flex justify-content-end">
							<a
								href=""
								className="footer-icon"
								href="https://twitter.com/FertileGuppies"
							>
								<i class="fab fa-twitter"></i>
							</a>
							<a
								href=""
								className="footer-icon"
								href="https://discord.com/invite/yAjy7Tfkm4"
							>
								<i class="fab fa-discord"></i>
							</a>
							<a
								className="nav-link d-flex flex-column align-items-center  "
								href="https://www.youtube.com/channel/UCwBc3fO4tDgpkCLw0fwLRHw"
							>
								<i class="fab fa-youtube"></i>
							</a>
							{/* <a href="" className="footer-icon">
                <i class="fab fa-medium"></i>
              </a> */}
							<a href="" className="footer-icon">
								<img src={openseaImg} alt="" height="25px" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Footer;
