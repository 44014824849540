import React from "react";
import "./Team.css";
import team1 from "./assets/69.png";
import team2 from "./assets/85.png";
import team3 from "./assets/65.png";
import team4 from "./assets/88.png";

const Team = () => {
  return (
    <>
      <div className="container-fluid bg-hero py-5">
        <div className="container">
          <h2>The Team</h2>
          <div className="row py-5 px-md-5">
            <div className="col-md-3">
              <img src={team1} alt="" className="img-fluid round-img" />
              <h4 className="mt-2">Mcal0066</h4>
              <p>Project Manager</p>
              <a href="https://twitter.com/lizards_feet">
                <i class="fab fa-twitter"></i>
              </a>
            </div>
            <div className="col-md-3">
              <img src={team2} alt="" className="img-fluid round-img" />
              <h4 className="mt-2">Tangent</h4>
              <p>Black Chain Expert</p>
              <a href="https://twitter.com/Tangent893">
                <i class="fab fa-twitter"></i>
              </a>
            </div>
            <div className="col-md-3">
              <img src={team3} alt="" className="img-fluid round-img" />
              <h4 className="mt-2">Basement Devs</h4>
              <p>Development Team</p>
              <a href="https://twitter.com/baseme_devsNFT">
                <i class="fab fa-twitter"></i>
              </a>
            </div>
            <div className="col-md-3">
              <img src={team4} alt="" className="img-fluid round-img" />
              <h4 className="mt-2">Faucs33</h4>
              <p>Community Lead</p>
              <a href="https://twitter.com/ErikFaucon">
                <i class="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
