import React from "react";
import staking from "./assets/Staking.jpg";
const Staking = () => {
	return (
		<div className="container-fluid bg-hero" id="Staking">
			<div className="container">
				<img src={staking} alt="" className="img-fluid w-100" />
			</div>
		</div>
	);
};

export default Staking;
