import React from "react";

const Story = () => {
  return (
    <>
      <div className="container-fluid bg-hero py-5">
        <div className="container px-md-5 py-5">
          <h1> What Sets Us Apart:</h1>
          <br />
          <p>
            We don’t know of any other NFT project who is focused on providing
            service-oriented utility to their holders. We’ve established a
            community of both veteran and new NFT collectors and we welcome all
            with open arms. We believe that we can navigate the OpenSea stronger
            as a collective and want to do so TOGETHER!
            <br />
            <br />
            Our Vision is to always have our community front and center as we
            grow and develop new tools to help our members navigate the
            ever-changing NFT market.
            <br />
            <br />
            Our goal is to be one of the strongest communities of NFT
            enthusiasts. We will always be there for our members at all times by
            providing tailored, white-glove services to our community.
            <br />
            <br />
            We have focused on building a community and providing utility to
            both our NFT and GupBux token. By swimming together we can all be
            stronger and safer in this big OpenSea.
            <br />
            <br />
            Our roadmap has been created so that we can deliver our promise to
            our users. While we have many big plans, we believe in under
            promising and over delivering.
            <br />
            <br />
            <span className="tags">#</span>
            GupshelpingGups <span className="tags">#</span>GupFam
          </p>
        </div>
      </div>
    </>
  );
};

export default Story;
