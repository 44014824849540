import "./App.css";
import { useState } from "react";
import Drop from "./components/Drop";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Home from "./components/Home";
import Nav from "./components/Nav";
import Roadmap from "./components/Roadmap";
import Story from "./components/Story";
import Team from "./components/Team";
import Service from "./components/Service";
import Staking from "./components/Staking";

function App() {
	const [connecctstatus, setConnectedstatus] = useState(false);

	return (
		<div className="App">
			<Nav connecctstatus={connecctstatus} />
			<Hero
				connecctstatus={connecctstatus}
				setConnectedstatus={setConnectedstatus}
			/>
			<Home />
			<Drop />
			<Service />
			<Staking />
			<Story />
			<Roadmap />
			<Team />
			<Footer />
		</div>
	);
}

export default App;
