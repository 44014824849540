import React from "react";
import drop from "./assets/Drop2.jpg";

const Drop = () => {
	return (
		<>
			<div className="container-fluid bg-hero" id="Drop">
				<div className="container">
					<img src={drop} alt="" className="img-fluid w-100" />
				</div>
				<div className="container px-md-5 py-5">
					<h1>
						We are splitting our launch into three drops, building in quantity
						and price. This will provide many benefits:
					</h1>
					<br />
					<p>
						1. Ensure we can scale our services as new members join
						<br />
						<br />
						2. Organically build a solid community of like-minded enthusiasts
						<br />
						<br />
						3. Limit supply and build a very solid price floor.
						<br />
						<br />
						4. Tiered pricing to build a strong growing floor price.
					</p>
				</div>
			</div>
		</>
	);
};

export default Drop;
