import React from "react";
import "./Home.css";
import guppie from "./assets/2.png";
import guppie2 from "./assets/69.png";
import guppie3 from "./assets/85.png";
import guppie4 from "./assets/88.png";

const Home = () => {
	return (
		<>
			<div className="container-fluid bg-home">
				<div className="container">
					<div className="row py-5 align-items-center justify-content-between">
						<div className="col-md-5">
							<div className="row p-5">
								<div className="col-6 mt-4">
									<img
										src={guppie4}
										alt=""
										className="img-fluid rounded rounded-lg"
									/>
								</div>
								<div className="col-6 mt-4">
									<img src={guppie2} alt="" className="img-fluid rounded" />
								</div>
								<div className="col-6 mt-4">
									<img src={guppie} alt="" className="img-fluid rounded" />
								</div>
								<div className="col-6 mt-4">
									<img src={guppie3} alt="" className="img-fluid rounded" />
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<h1>What are Fertile Guppies?</h1>
							<br />
							<p>
								A collection of 7898 unique NFTs with over 100 different traits.
								<br />
								<br />
								We are all about service as a utility.
								<br />
								<br />
								Buy a Fertile Guppies NFT and reap the rewards of staking your
								NFT. By offering a unique staking platform, our members can pay
								for our  services with staking reward &nbsp;
								<strong>
									<a href="https://discord.com/invite/yAjy7Tfkm4">$Fish </a>
									&nbsp;
								</strong>
								in Discord.
								<br />
								<br />
								We will also open breeding season between  drops  and reward
								anyone staking both a Male and Female Guppy with an airdropped
								Fertile Guppies NFT. Our goal is to continuously provide
								White-glove service to NFT collectors who hold and stake a
								Guppy. See Full list of services &nbsp;
								<strong>
									<a href="#Service"> HERE.</a> &nbsp;
								</strong>
							</p>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;
