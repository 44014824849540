import React from "react";
import { useState, useEffect } from "react";
import Web3 from "web3";
import "./Nav.css";
import logo from "./assets/logo.jpg";
import openseaImg from "./assets/opensea.svg";

const Nav = ({ setconnecctstatus, connecctstatus }) => {
	const [connectedAccount, setConnectedAccount] = useState("Connect Wallet");
	useEffect(() => {
		if (connecctstatus) {
			connectWallet();
		}
	});

	async function connectWallet() {
		if (window.ethereum) {
			window.web3 = new Web3(window.ethereum);
			await window.ethereum.enable();
			const web3 = window.web3;
			const metaMaskAccount = await web3.eth.getAccounts();

			let splitedMetaMaskAddress;
			if (metaMaskAccount) {
				splitedMetaMaskAddress =
					metaMaskAccount[0].substring(0, 6) +
					"......" +
					metaMaskAccount[0].substring(
						metaMaskAccount[0].length - 4,
						metaMaskAccount[0].length
					);
			}
			setConnectedAccount(splitedMetaMaskAddress);
			console.log("CCC", connecctstatus);
		} else if (window.web3) {
			window.web3 = new Web3(window.web3.currentProvider);
		} else {
			window.alert(
				"Non-Ethereum browser detected. You should consider trying MetaMask!"
			);
		}
	}
	return (
		<>
			<nav className="navbar navbar-expand-lg navbar-dark fixed-top">
				<div className="container px-md-5 py-3 align-items-center">
					<a className="navbar-brand" href="#">
						<img src={logo} alt="" height="100px" />{" "}
						<span className="logo">FERTILE GUPPIES</span>
					</a>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarNavAltMarkup"
						aria-controls="navbarNavAltMarkup"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarNavAltMarkup">
						<div className="navbar-nav ms-auto align-items-center">
							<a
								className="nav-link active"
								aria-current="page"
								href="#Roadmap"
							>
								Roadmap
							</a>
							<a className="nav-link" href="#Drop">
								Drop
							</a>
							<a className="nav-link disable" href="#Staking">
								Staking Pool (Staking is live).
							</a>
							<a
								className="nav-link d-flex flex-column align-items-center"
								href="https://twitter.com/FertileGuppies"
							>
								<i class="fab fa-twitter"></i>
							</a>
							<a
								className="nav-link d-flex flex-column align-items-center  "
								href="https://discord.com/invite/yAjy7Tfkm4"
							>
								<i class="fab fa-discord"></i>
							</a>
							<a
								className="nav-link d-flex flex-column align-items-center  "
								href="https://www.youtube.com/channel/UCwBc3fO4tDgpkCLw0fwLRHw"
							>
								<i class="fab fa-youtube"></i>
							</a>
							<a
								className="nav-link d-flex flex-column align-items-center "
								href=""
							>
								<img src={openseaImg} alt="" height="26px" />
							</a>
							<a
								className="nav-link connect-btn"
								onClick={() => {
									connectWallet();
								}}
							>
								{connectedAccount}
							</a>
						</div>
					</div>
				</div>
			</nav>
		</>
	);
};

export default Nav;
