import React from "react";
import rm from "./assets/Roadmap.jpg";
const Roadmap = () => {
  return (
    <>
      <div className="container-fluid" id="Roadmap">
        <div className="container">
          <img src={rm} alt="" className="img-fluid w-100" />
        </div>
      </div>
    </>
  );
};

export default Roadmap;
