import React from "react";
import "./Hero.css";
import heroimg from "./assets/Mainf.jpeg";
import herogif from "./assets/guppies.gif";
import Web3 from "web3";
import abi from "./abi.json";
import { useEffect, useState } from "react";

require("dotenv").config();
const { REACT_APP_CONTRACT_ADDRESS } = process.env;

const Hero = ({ connecctstatus, setConnectedstatus }) => {
	const [connectedAccount, setConnectedAccount] = useState("Connect Wallet");
	const [contract, setContract] = useState(null);
	const [tokenId, setTokenId] = useState(null);
	const [supply, setTokenSupply] = useState(null);
	const [price, setPrice] = useState();
	const [priceInEth, setPriceInEth] = useState(0.06);
	const [quantity, setQuantity] = useState(1);
	const [minted, setMinted] = useState(false);
	console.log("C", connecctstatus);

	useEffect(() => {
		loadWeb3();
	}, []);

	async function loadWeb3() {
		if (window.ethereum) {
			window.web3 = new Web3(window.ethereum);
			// await window.ethereum.enable();
			const web3 = window.web3;
			// creating contract instance
			const contractaddress = REACT_APP_CONTRACT_ADDRESS;
			const ct = new web3.eth.Contract(abi, contractaddress);
			setContract(ct);
			console.log("ct", ct);
			let price = await ct.methods.secondPrice().call();
			setContract(ct);
			setPrice(price);
			setPriceInEth(web3.utils.fromWei(price, "ether"));
			const totalSupply = await ct.methods.totalSupply().call();
			setTokenSupply(totalSupply);
		} else if (window.web3) {
			window.web3 = new Web3(window.web3.currentProvider);
		} else {
			window.alert(
				"Non-Ethereum browser detected. You should consider trying MetaMask!"
			);
		}
	}
	async function mint() {
		const web3 = window.web3;
		const _value = price * quantity;
		const address = await web3.eth.getAccounts();

		await contract.methods
			.secondAirDrop(quantity)
			.send({ from: address.toString(), value: _value })
			.then((resp) => {
				console.log("RESP", resp);
			});
		setMinted(true);
		const totalSupply = await contract.methods.totalSupply().call();
		setTokenSupply(totalSupply);
	}
	async function connectWallet() {
		if (window.ethereum) {
			window.web3 = new Web3(window.ethereum);
			await window.ethereum.enable();
			const web3 = window.web3;
			const metaMaskAccount = await web3.eth.getAccounts();
			setConnectedstatus(true);
			let splitedMetaMaskAddress;
			if (metaMaskAccount) {
				splitedMetaMaskAddress =
					metaMaskAccount[0].substring(0, 6) +
					"......" +
					metaMaskAccount[0].substring(
						metaMaskAccount[0].length - 4,
						metaMaskAccount[0].length
					);
			}
			setConnectedAccount(splitedMetaMaskAddress);
		} else if (window.web3) {
			window.web3 = new Web3(window.web3.currentProvider);
		} else {
			window.alert(
				"Non-Ethereum browser detected. You should consider trying MetaMask!"
			);
		}
	}

	async function Mint() {
		const web3 = window.web3;
		const _value = price * quantity;
		const address = await web3.eth.getAccounts();
		await contract.methods
			.secondAirDrop(quantity)
			.send({ from: address.toString(), value: _value });
		setMinted(true);
		const totalSupply = await contract.methods.totalSupply().call();
		setTokenSupply(totalSupply);
	}

	async function connectWallet() {
		if (window.ethereum) {
			window.web3 = new Web3(window.ethereum);
			await window.ethereum.enable();
			const web3 = window.web3;
			const metaMaskAccount = await web3.eth.getAccounts();
			let splitedMetaMaskAddress;
			if (metaMaskAccount) {
				splitedMetaMaskAddress =
					metaMaskAccount[0].substring(0, 6) +
					"......" +
					metaMaskAccount[0].substring(
						metaMaskAccount[0].length - 4,
						metaMaskAccount[0].length
					);
			}
			setConnectedAccount(splitedMetaMaskAddress);
		} else if (window.web3) {
			window.web3 = new Web3(window.web3.currentProvider);
		} else {
			window.alert(
				"Non-Ethereum browser detected. You should consider trying MetaMask!"
			);
		}
	}
	return (
		<>
			<div className="container-fluid bg-hero">
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<div className="container ">
					<img src={heroimg} alt="" className="w-100 fiximg" />
					{/* <div className="row py-5 justify-content-around">
            <div className="col-md-5 mt-2">
              <h1 className="display-5">Welcome to Firtile Guppies</h1>
              <p>
                Firtile Guppies has built a collection of 10,000 unique NFTs
                that grant you membership to her Country Club, access to Club
                benefits, and a chance to shape the Club's future. All are
                welcome, join today!
              </p>
              <div class=" btngroup">
                <div
                  class="d-flex rounded btngroup justify-content-start pt-3"
                  role="group"
                  aria-label="First group"
                >
                  <button
                    type="button"
                    class="btn bg-white text-black fw-bold "
                    onClick={() => {
                      if (quantity > 1) {
                        setQuantity(quantity - 1);
                      }
                    }}
                  >
                    -
                  </button>
                  <button type="button" class="btn text-white">
                    {quantity}
                  </button>
                  <button
                    type="button"
                    class="btn bg-white fw-bold"
                    onClick={() => {
                      if (quantity < 20) {
                        setQuantity(quantity + 1);
                      }
                    }}
                  >
                    +
                  </button>
                </div>
                <br />
                <a
                  className="btn connect-btn px-5 text-white"
                  onClick={async () => {
                    await connectWallet();
                    mint();
                  }}
                >
                  Mint Now
                </a>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <img src={herogif} alt="" className="img-fluid round-img" />
            </div>
          </div> */}
				</div>
				<div className="container">
					<div className="row py-5">
						<div className="col-md-6">
							<h1>Welcome to the Fertile Guppies!</h1>
							<p>
								We are a community based NFT project with service as utility. We
								hope to see you join our community in discord and help us grow
								the future of the Fertile Guppies NFT project.
							</p>
						</div>
						<div className="col-md-6">
							<div class=" btngroup">
								<div
									class="d-flex rounded btngroup justify-content-center"
									role="group"
									aria-label="First group"
								>
									<button
										type="button"
										class="btn bg-white text-black fw-bold "
										onClick={() => {
											if (quantity > 1) {
												setQuantity(quantity - 1);
											}
										}}
									>
										-
									</button>
									<button type="button" class="btn text-white">
										{quantity}
									</button>
									<button
										type="button"
										class="btn bg-white fw-bold"
										onClick={() => {
											if (quantity < 5) {
												setQuantity(quantity + 1);
											}
										}}
									>
										+
									</button>
								</div>
								<p className="text-center py-5">
									<a
										className="btn connect-btn text-white"
										onClick={async () => {
											await connectWallet();
											mint();
										}}
									>
										Mint Now
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Hero;
